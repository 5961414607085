/* Mobile-Friendly .banner-section */
.banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh; /* Adjusted height for mobile */
  background-image: 
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.7) 75%
    );
  overflow: hidden;
  position: relative;
}

/* Mobile-Friendly .banner-content */
.banner-content {
  padding: 20px; /* Reduced padding for mobile */
  text-align: center;
  max-width: 90%; /* Adjusted max-width for mobile */
  color: #fff;
  transform: translateY(-5px); /* Slight adjustment */
  transition: transform 0.3s ease-in-out;
  margin-top: 20px;
}

.banner-content:hover {
  transform: translateY(-10px); /* Slight adjustment on hover */
}

/* Mobile-Friendly .banner-border */
.banner-border {
  display: inline-block;
  border-radius: 15px; /* Slightly reduced border radius for mobile */
  border: 2px solid transparent;
  box-shadow: 
    0 4px 10px rgba(0, 0, 0, 0.3), 
    0 0 20px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
  margin-top: 15px; /* Slightly reduced margin for mobile */
}

.banner-content:hover + .banner-border {
  transform: scale(1.1);
  border: 2px solid #007B00;
}

/* About Us Heading - Adjusted font size for mobile */
.banner-content h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #0077b6;
  line-height: 1.3;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  display: inline-block;
  margin: 0.5rem;
}

/* Mobile-Friendly .banner-text */
.banner-text {
  font-size: 1rem; /* Adjusted font size for mobile */
  margin-bottom: 1rem;
  color: #fff;
  margin-top: 15px; /* Slightly adjusted margin for mobile */
}

/* Background Gradient */
.banner-border::before {
  content: '';
  position: absolute;
  border-radius: 15px; /* Slightly reduced border radius for mobile */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(ellipse at left center, rgba(0, 255, 170, 0.3) 0%, transparent 20%, rgba(0, 255, 170, 0.3) 100%),
    radial-gradient(ellipse at left , rgba(0, 255, 170, 0.3) 0%, transparent 20%, rgba(0, 255, 170, 0.3) 100%),
    radial-gradient(ellipse at center, #00ffaa 0%, transparent 70%),
    radial-gradient(ellipse at left, #00ffaa 0%, transparent 70%);
  z-index: -1;
  opacity: 0.7;
}

/* Background Overlay */
.banner-border::after {
  content: '';
  position: absolute;
  border-radius: 15px; /* Slightly reduced border radius for mobile */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(
      45deg, 
      rgba(0, 0, 0, 0.75), 
      rgb(253, 1, 228) 1px, 
      rgb(16, 0, 95),
      rgba(0, 0, 0, 0.89) 75%
    );
  z-index: -2;
  mix-blend-mode: screen;
}

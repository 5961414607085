/* Default styles for desktop */
.app-header,
.app-header button {
  background: 
    linear-gradient(#009a66, #009a66, #009a66, #ffffff);
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  border-top-right-radius: 10px; /* Adjust the value as needed */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 0;
}

/* Default styles for mobile (overrides desktop styles) */
@media screen and (max-width: 768px) {
  .app-header,
  .app-header button {
    font-size: 3rem; /* Reduce font size for mobile */
    padding: 0.25rem 1rem;
  }

  /* Add other button styles here specific to mobile */
}

/* Additional styles for ::before and ::after */
.app-header::before,
.app-header::after {
  font-size: 1.5rem;
  font-weight: normal;
  margin-top: 1rem;
}

/* Additional styles for ::before and ::after on mobile (overrides desktop styles) */
@media screen and (max-width: 768px) {
  .app-header::before,
  .app-header::after {
    font-size: 1.25rem; /* Reduce font size for mobile */
  }
}

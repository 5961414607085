.diamond-container {
  position: relative;
  width: 100%;
  height: 25vh;
  overflow: hidden;
}

.diamond {
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: transparent;
  border: 2px solid #4299E1;
  transform: rotate(45deg);
}

.diamond::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='100%'><stop offset='0%' style='stop-color:rgba(192,132,252,0.11)' /><stop offset='15.74%' style='stop-color:rgba(14,165,233,0.41)' /><stop offset='56.49%' style='stop-color:rgba(232,121,249,0.26)' /><stop offset='115.91%' style='stop-color:rgba(79,70,229,0.4)' /></linearGradient><rect width='100%' height='100%' fill='url(%23gradient)' /></svg>");
  mask-image: url('../assets/diamond.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

  .diamond:nth-child(2) {
    left: 30%;
    top: 50%;
    animation: float1 4s infinite alternate;
  }
  
  .diamond:nth-child(3) {
    left: 60%;
    top: 20%;
    animation: float2 3s infinite alternate;
  }
  
  .diamond:nth-child(4) {
    left: 80%;
    top: 80%;
    animation: float3 5s infinite alternate;
  }
  
  @keyframes float1 {
    to {
      left: 30%;
      top: 20%;
    }
  }
  
  @keyframes float2 {
    to {
      left: 60%;
      top: 50%;
    }
  }
  
  @keyframes float3 {
    to {
      left: 80%;
      top: 20%;
    }
  }
  

.home-banner {
  color: #fff;
  height: 4in;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  margin-top: -3px;
  z-index: 1;
}


.home-banner-content {
  max-width: 38rem;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}


.home-banner-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #00ffaa;
}


.home-banner-text {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-bottom: 2rem;
}


.home-banner img {
  margin-top: 1in;
  max-width: 100%;
  height: auto;
  border: 2px solid #00ffaa;
  border-radius: 10px;
  animation: pulse 2s infinite alternate;
}

@keyframes moveShape {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
    animation-timing-function: ease-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.5), inset 0px 0px 5px rgba(255, 255, 255, 0.2); /* Blue lighting effect with circles */
  }
  50% {
    transform: translate(-50%, 50%) rotate(45deg) scale(1.2); /* Move down and enlarge */
    animation-timing-function: ease-in-out;
    box-shadow: 0px 0px 20px rgba(0, 0, 255, 0.8), inset 0px 0px 10px rgba(255, 255, 255, 0.2); /* Increase lighting effect with circles */
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
    animation-timing-function: ease-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.5), inset 0px 0px 5px rgba(255, 255, 255, 0.2); /* Restore lighting with circles */
  }
}


.home-banner::before {
  content: '';
  position: absolute;
  border-radius: 50%; /* Apply a border-radius to make it round */
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: 
    radial-gradient(ellipse at center, rgba(0, 255, 170, 0.3) 0%, transparent 50%, rgba(0, 255, 170, 0.3) 100%),
    radial-gradient(ellipse at center, #00ffaa 0%, transparent 70%);
  animation: 
    moveShape 25s ease-in-out infinite;
  z-index: -1;
  opacity: 0.7;
}

.home-banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
  linear-gradient(
      45deg, 
      rgba(0, 0, 0, 0.75), 
      rgb(253, 1, 228) 1px, 
      rgb(16, 0, 95),
      rgba(0, 0, 0, 0.89) 75%
    );
  z-index: -2;
  animation: rotate 60s linear infinite;
  mix-blend-mode: screen;
}


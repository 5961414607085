/* About Us Container */
.about-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.75),
        rgb(253, 1, 228) 90%,
        rgba(0, 0, 0, 0.89) 75%
    );
    overflow: hidden;
    position: relative;
    z-index: 1;
}

/* About Us Content */
.about-us-content {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    text-align: left;
    max-width: 800px;
    color: #95a5a6;
    position: relative;
}

/* About Us Heading */
.about-us-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #e74c3c;
    line-height: 1.3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-weight: 600;
}

/* About Us Paragraph */
.about-us-content p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #bdc3c7;
}

/* Style each word as a tile within the paragraphs */
.about-us-content span {
    display: inline-block;
    padding: 5px 10px;
    background-color: #3498db;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

/* Wild Art Container */
.wild-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    min-height: 50vh;
    padding: 30px 0;
}

/* Wild Art Image */
.wild_art {
    height: 40vmin;
    pointer-events: none;
    filter: hue-rotate(90deg);
    animation: wild_art-spin infinite 600s linear;
}

@media (prefers-reduced-motion: no-preference) {
    .wild_art {
        animation: wild_art-spin infinite 5000s linear;
    }
}

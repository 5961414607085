.contact-banner {
  text-align: center;
  position: relative;
  padding: 50px;
  color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 215, 57, 0.896);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  transition: transform 0.2s ease-in-out;
  margin: 0 auto;
  place-items: center;
  display: grid;
  overflow: hidden;
  background-size: 200% 200%;
  animation: pulsate 3s alternate infinite, dance 5s linear infinite;
}

.contact-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  transition: background-color 0.3s ease-in-out;
}

.contact-banner:hover::before {
  background-color: rgba(0, 0, 0, 0.15);
}

.contact-banner:hover {
  transform: translateY(-3px);
}
.contact-shape {
  position: absolute;
  background: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: spin 10s linear infinite, moveUpDown 6s ease-in-out infinite;
}

.contact-shape::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.contact-shape::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: #e74c3c;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.contact-banner {
 
  position: relative;
  overflow: hidden;
  background-image: 
  linear-gradient(
      rgba(0, 0, 0, 0.75), 
      rgb(253, 1, 228) 90%, 
      rgba(0, 0, 0, 0.89) 75%
    );

  background-size: 200% 200%;
  animation: pulsate 3s alternate infinite, dance 5s linear infinite;
}


.contact-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 50% 100%, rgba(255, 255, 255, 0.5) 5%, transparent 50%);
  background-size: 100px 100px;
  pointer-events: none;
  animation: flames 3s linear infinite alternate;
}


@keyframes flames {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.discordLogoShort {
  max-width: 30%;
  height: auto;
  margin: 20px 0;
  fill: white;
  transition: transform 0.2s ease-in-out;
}

.discordLogoShort:hover {
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.disc-button {
  padding: 12px 24px;
  background: linear-gradient(45deg, rgba(0, 255, 255, 0.8), rgba(255, 0, 170, 0.8));
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  overflow: hidden;
}

.disc-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 255, 255, 0.5), rgba(255, 0, 170, 0.5));
  z-index: -1;
  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: right;
}

.disc-button:hover::before {
  transform: scaleX(1);
}

.disc-button:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.contact-banner:hover {
  transform: translateY(-3px);
}

.contact-banner a {
  text-decoration: none;
  color: white;
}

.contact-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.contact-icons a {
  margin: 0 20px;
  transition: color 0.3s ease-in-out;
  font-size: 24px;
}

.contact-icons a:hover {
  color: #3182ce;
}

.contact-text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.5;
}

.App {
  text-align: center;
}

.auth {
  font-weight: bold;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff; /* Dark gray text color */
  text-align: center; /* Center the text horizontally */
}
/* Base Styles */
.player-container, .player-text {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #191818; /* Dark gray text color */
  text-align: center; /* Center the text horizontally */

}

.player-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #000;
  border-radius: 10px;
}

.player {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* Mobile-Friendly Styles (applies to screens up to 768px width) */
@media (max-width: 768px) {
  .player-container {
    /* Center the content both horizontally and vertically on mobile */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add some padding for better mobile layout */
  }
}

/* Web Styles (applies to screens wider than 768px) */
@media (min-width: 769px) {
  .player-container {
    /* Reset the display properties for web screens */
    display: block;
    text-align: center; /* Center the content horizontally on web */
  }
}


.clouds {
  position: absolute;
  animation: clouds-float 20s ease-in-out infinite alternate;
  right: 0;
  margin-bottom:85%;
  width: 100px; 
  height: 100px;
}
.cloudsAgain {
  position: absolute;
  animation: clouds-float 15s ease-in-out infinite alternate;
  right: 0;
  margin-bottom:76%;
  width: 100px;
  height: 100px;
}

@keyframes clouds-float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.core-values {
  height: 40vmin;
}
.yolo {
  height: 40vmin;
}
.AppBar {
  color: #ffff;
}

.App-link {
  color: #ffff;
}

@keyframes wild_art-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-body {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-repeat: no-repeat;
}

.App-body {
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px;
  background-size: cover;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.75) 25%,
    rgba(0, 0, 0, 0.75) 50%,
    rgba(0, 0, 0, 0.75) 75%,
    rgba(0, 0, 0, 0.89) 75%
  );
}
.App-body {
  background-color: linear-gradient(45deg, rgba(0, 255, 255, 0.8), rgba(255, 0, 170, 0.8));
  color: white;
  text-align: center;
}
.App-body {
  height: 100vh;
  background: linear-gradient(45deg, #3498db, #e74c3c);
  background-size: 200% 200%;
  animation: gradientTransition 50s linear infinite;
}

@keyframes gradientTransition {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.App-body .wild_art {
  max-width: 100%;
  height: 23%;
}

.App button {
  background: linear-gradient(45deg, rgba(0, 255, 255, 0.8), rgba(255, 0, 170, 0.8));
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.App button:hover {
  background: linear-gradient(45deg, rgba(255, 0, 170, 0.8), rgba(0, 255, 255, 0.8));
  transform: scale(1.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}



.App button:hover {
  background: linear-gradient(45deg, rgba(255, 0, 170, 0.8), rgba(0, 255, 255, 0.8));
  transform: scale(1.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}


.App-body p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 20px 0;
}
.footer {
  height: 40vh;
}
.outlookLogoShort{
  height: 10vh;
} 
.discordLogoLong{
  height: 10vh;
} 
.social-container {
  background: #eee;
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-2px);
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}